<template>
    <v-container v-show="feedbacks.length !== 0" style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap; gap:2rem;">
      <v-card v-for="(feedback, index) in feedbacks" :key="index" class="mb-4">
        <v-card-title>{{ feedback.name }}</v-card-title>
        <v-card-subtitle>{{ feedback.email }}</v-card-subtitle>
        <v-card-text>{{ feedback.message }}</v-card-text>
  
        <!-- delete icon -->
        <v-card-actions>
          <v-btn icon @click="openDeleteDialog(feedback.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
  
      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="dialog" max-width="400px">
        <v-card>
          <v-card-title class="headline">Confirm Deletion</v-card-title>
          <v-card-text>Are you sure you want to delete this feedback?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import feedbackService from '@/services/feedbackService.js'; // Adjust the path as needed
  
  export default {
    data() {
      return {
        feedbacks: [],
        dialog: false,
        feedbackIdToDelete: null,
      };
    },
    mounted() {
      this.getFeedbacks();
    },
    methods: {
      getFeedbacks() {
        feedbackService.getAllFeedbacks()
          .then(response => {
            this.feedbacks = response.data.data.results;
          })
          .catch(error => {
            console.log(error);
            this.$emit("updateSnackbar", 'error');
          });
      },
      openDeleteDialog(id) {
        this.feedbackIdToDelete = id;
        this.dialog = true;
      },
      confirmDelete() {
        if (this.feedbackIdToDelete !== null) {
          this.deleteFeedback(this.feedbackIdToDelete);
          this.feedbackIdToDelete = null;
        }
        this.dialog = false;
      },
      deleteFeedback(id) {
        feedbackService.deleteFeedback(id)
          .then(response => {
            // remove the feedback from the list
            this.feedbacks = this.feedbacks.filter(feedback => feedback.id !== id);
            this.$emit("updateSnackbar", response.data.message);
          })
          .catch(error => {
            this.$emit("updateSnackbar", error);
          });
      }
    }
  }
  </script>
  