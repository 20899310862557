<template>
  <v-app style = "overflow-y:auto">
    <v-main style = "overflow-y:auto">
      <router-view style = "overflow-y:auto"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style>
  html { overflow-y: auto !important;} 
</style>