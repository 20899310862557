import api from '@/services/api'


export default {
    registerUser(params) {
        return api().post('/auth/register', params)
    },

    loginUser(params) {
        return api().post('/auth/login',params)
    },

    checkAuthUser() {
        return api().get('/auth/checkAuth')
    }
}