<template>
    <v-container class="fill-height" fluid theme="dark" style = "background-color: rgb(45, 43, 43);">
      <v-row justify="center" align="center" theme="dark" >
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12" theme="dark">
            <v-toolbar color="primary" dark>
              <v-toolbar-title theme="dark">Admin Registration(Please contact Chris for assistance with registration)</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <!-- call registerUser -->
              <v-form 
              >
                <v-text-field
                  label="Username"
                  prepend-icon="mdi-account"
                  type="text"
                  required
                  v-model="username"
                ></v-text-field>
                <v-text-field
                  label="Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                  v-model="password"
                ></v-text-field>
                <v-text-field
                  label="adminCode"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                  v-model="adminPassword"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark @click = "registerUser">Register</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar
        v-show = "snackbar.show"
        v-model="snackbar.show"
        :color="snackbar.color"
        timeout="3000"
        bottom
      >
        {{ axiosErrorMsg }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script>
  import authService from '@/services/authService.js';

  export default {
    name: 'AdminRegister',
    data: () => ({
      username: '',
      password: '',
      adminPassword: '',
      axiosErrorMsg: '',
      snackbar: {
        show: false,
        color: 'error',
      }
    }),
    methods: {
      async registerUser() {
        // console.log(this.username, this.password, this.adminPassword)
        // let res = authService.registerUser(this.username, this.password, this.adminPassword)

        // registerUser takes a single param object
        let res;
        try{
          res = await authService.registerUser({
            username: this.username,
            password: this.password,
            adminCode: this.adminPassword
          })

          if (res.status === 201) {
            // point router to AdminLogin
            this.$router.push({ path: '/ppauth/adminLogin', query: { registered: 'true' } });
          } else {
            console.log('Error registering user')
          }
        }
        catch (error) {
          this.axiosErrorMsg = error.response.data.message;
          this.snackbar.show = true;
          console.log("Error registering user: ", error.response.data.message)
        }
      }
    }
  };
  </script>
  
  <style>
  </style>