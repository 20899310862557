<template>
    <v-container class="fill-height" fluid theme="dark" style = "background-color: rgb(45, 43, 43);">
      <v-row justify="center" align="center" theme="dark" >
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12" theme="dark">
            <v-toolbar color="primary" dark>
              <v-toolbar-title theme="dark">Admin Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Username"
                  v-model = "username"
                  prepend-icon="mdi-account"
                  type="text"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click = "userLogin()" dark>Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar
        v-show = "snackbar.show"
        v-model="snackbar.show"
        :color="snackbar.color"
        timeout="6000"
        bottom
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script>
  import authService from '@/services/authService'

  export default {
    name: 'AdminLogin',
    data: () => ({
      username: '',
      password: '',
      snackbar: {
        show: false,
        color: 'success',
        text: ''
      }
    }),
    mounted() {
      //  if register is successful, reroute to this page and show notification to notify success
      if (this.$route.query.registered === 'true') {
        this.snackbar.show = true
        this.snackbar.color = 'success'
        this.snackbar.text = 'Registration successful. Please login.'
      }
    },
    methods: {
      async userLogin() {
        try {
          let res = await authService.loginUser({ username: this.username, password: this.password });
          if (res.status === 200) {
            this.$router.push(res.data.data.redirectPath);
          } else {
            this.snackbar.show = true;
            this.snackbar.color = 'error';
            this.snackbar.text = 'Invalid username or password. Please try again.';
          }
        } catch (error) {
          this.snackbar.show = true;
          this.snackbar.color = 'error';

          if(error.response.status === 401) {
            this.snackbar.text = 'Invalid username or password. Please try again.';
          } else {
            this.snackbar.text = 'An unexpected error occurred. Please try again.';
          }
        }
      }
    }
  };
  </script>
  
  <style>
  </style>