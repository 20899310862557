import api from '@/services/api'

// http GET :5000/wheelsets/206. 

export default {
    getAllWheelsets() {
        return api().get('/wheelsets')
    },
    createWheelset(params) {
        // convert params to form data
        let formData = new FormData();
        
        for (let key in params) {
            formData.append(key, params[key])
        }

        return api().post('/wheelsets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    getAllWheelsetsAdmin() {
        return api().get('/wheelsets/admin')
    },

    updateWheelset(id, params) {
        // convert params to form data
        let formData = new FormData();
        for (let key in params) {
            formData.append(key, params[key])
        }

        return api().put(`/wheelsets/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    getWheelset(params) {
        return api().get(`/wheelsets/${params.id}`)
    },
    deleteWheelset(id) {
        return api().delete(`/wheelsets/${id}`)
    }
}