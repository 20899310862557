// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  {theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#1976D2',  // Customize your color
        background: '#121212' // Default dark background
      }
    }
  }}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
