<template>
    <v-container style="display: flex; justify-content: center; align-items: center;">
      <v-btn 
        @click="dialog = true" 
        class="me-2 text-none"
        color="#4f545c"
        prepend-icon="mdi-export-variant"
        variant="flat"
      >
        Feedback
      </v-btn>
      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-card-title>
            Feedback Form  ( * = required )
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
                <v-text-field v-model="name" label="Name" outlined></v-text-field>
                <v-text-field v-model="email" label="Email" :rules="[emailRule]" required outlined></v-text-field>
                <v-textarea v-model="feedback" label="Feedback" :rules="[feedbackRule]" required outlined></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="submitFeedback">Submit</v-btn>
            <v-btn color="error" @click="closeDialog">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import feedbackService from '@/services/feedbackService.js'; // Adjust the path as needed
  
  export default {
    data() {
      return {
        dialog: false,
        name: '',
        email: '',
        feedback: '',
        emailRule: this.validEmail,
        feedbackRule: this.validFeedback
      };
    },
    methods: {
        emailRequired(v) {
            return !!v || 'Email is required';
        },
        validEmail(v) {
            return /.+@.+\..+/.test(v) || 'Email must be valid';
        },
        feedbackRequired(v) {
            return !!v || 'Feedback is required';
        },
        validFeedback(v) {
            return v.split(' ').length > 10 || 'Feedback must be more than 10 words';
        },
        async submitFeedback() {
            let valid = await this.$refs.form.validate();

            if (valid.valid) {
                const feedbackData = {
                  name: this.name,
                  email: this.email,
                  feedback: this.feedback
                };
            
            this.closeDialog();

            feedbackService.createFeedback(feedbackData)
                .then(response =>{
                  this.$emit("updateSnackbar", response.data.message);
                })
                .then(() => {
                })
                .catch(error =>{
                  this.$emit("updateSnackbar", error);
                })
            }
        },
        closeDialog() {
          this.dialog = false;
          this.clearForm();
        },
        clearForm() {
          this.name = '';
          this.email = '';
          this.feedback = '';
        }
  }
};
</script>