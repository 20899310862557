  export default {
    install(app, options) {
      // Extract tracking ID from options or use a default
      const gaTrackingId = options?.id || 'G-826BJFNBG2';
  
      // Inject the Google Analytics script into the document
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
      script.async = true;
      document.head.appendChild(script);
  
      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', gaTrackingId);
  
      // Make `gtag` accessible in Vue components
      app.config.globalProperties.$gtag = gtag;
    }
  };