import api from '@/services/api'
 

export default {
    createFeedback(params) {
        return api().post('/feedback', params)
    },
    getAllFeedbacks() {
        return api().get('/feedback')
    },
    deleteFeedback(id) {
        return api().delete(`/feedback/${id}`)
    }
}