<!-- issues -->

<template>
  <div style = "overflow-y: none;" class = "mainCont">
      
      <!-- TITLE -->
      <v-row 
          no-gutters 
          style = "justify-content: center; align-items: center; "
          :style = "{ 'margin-top' : window.width >=768 ? '2rem' : '1rem'}"
      >
          <v-col cols="auto" v-show="window.width >= 768" name="fade" is="transition-group">
              <v-img src="/images/wheelsetCompare/wheelUI.png" max-height="100" style = "color: white; min-width: 100px; padding-right:2rem" contain></v-img>
          </v-col>
          <v-col cols="auto">
              <h1 style = "  ">ADMIN EDITING PAGE</h1>
          </v-col>
      </v-row>


      <!-- MAIN -->
      <v-container 
          :class = "{'nonAdvancedMode':!advancedMode && window.width >= 768}" 
          :style = "{ 'margin-top' : window.width >=768 ? '1rem' : '-1rem'}"
      >
          <v-data-table
              v-model="selected"
              :headers="currentHeaders"
              :items= "currentItems"
              :items-per-page="0"
              ref="dataTable"
              show-select
              select-strategy="single"
              item-value = "name"
              :custom-key-sort="customKeySort"
          >
              <template v-slot:top>
                <v-toolbar
                  flat
                  style = "margin-bottom: 1rem;"
                >
                  <v-toolbar-title>ADMIN CONTROL</v-toolbar-title>
                  <!-- <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider> -->
                  <v-spacer></v-spacer>

                  <!-- CREATE NEW POP-UP -->
                  <v-dialog
                    v-model="dialog"
                    max-width="1000px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="props"
                      >
                        New Item
                      </v-btn>
                    </template>

                  
                    <v-card >
                      <v-card-title>
                        <span >Add new item (* = required)</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <!-- upload image(file upload) -->
                            <v-col cols="12" sm="6" md="4">
                              <v-file-input label="*image(.png or .jpg)" accept="image/png, image/jpeg" v-model = "createNewPopUpWindowItems.imgFile" ></v-file-input>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.name" label="*Name"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.shortName" label="*Short Name"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.price" label="*Price($)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.DiscOrRim" label="*Disc or Rim"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.weight" label="*Weight(g)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.clincherOrTubelessOrHookless" label="*Clincher or Tubeless or Hookless"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.frontSpokeCount" label="Front Spoke Count"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.frontRimDepth" label="Front Rim Depth(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.frontRimInnerWidth" label="Front Rim Inner Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.frontRimOutterWidth" label="Front Rim Outter Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.rearSpokeCount" label="Rear Spoke Count"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.rearRimDepth" label="Rear Rim Depth(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.rearRimInnerWidth" label="Rear Rim Inner Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.rearRimOutterWidth" label="Rear Rim Outter Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.riderWeightLimit" label="Rider Weight Limit(kg)"></v-text-field>
                            </v-col>  
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.spokeType" label="Spoke Type"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.warranty" label="Warranty(yr)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.link" label="PandaPodium Link"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="createNewPopUpWindowItems.ppId" label="PandaPodium id"></v-text-field>
                            </v-col>

                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue-darken-1"
                          variant="text"
                          @click="cancelAddWheel"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue-darken-1"
                          variant="text"
                          @click="postWheelset"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- DELETE POP-UP -->
                  <v-dialog v-model="dialogDelete" max-width="800px">
                    <v-card>
                      <div 
                        class="v-card-title" 
                        style="
                        display: flex;
                        justify-content: center;
                        margin-top: 0.8rem;
                        border-radius: 45px;"
                      >
                      Are you sure you want to delete this item? This is a irreversible action.
                    </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- EDIT POP-UP -->
                  <v-dialog
                    v-model="editDialog"
                    max-width="1000px"
                  >

                  
                    <v-card >
                      <v-card-title>
                        <span >Edit Item (* = required, -1 means undefined)</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <!-- upload image(file upload),  -->
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.name" label="*Name"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.shortName" label="*Short Name"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.price" label="*Price($)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.DiscOrRim" label="*Disc or Rim"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.weight" label="*Weight(g)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.clincherOrTubelessOrHookless" label="*Clincher or Tubeless or Hookless"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.frontSpokeCount" label="Front Spoke Count"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.frontRimDepth" label="Front Rim Depth(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.frontRimInnerWidth" label="Front Rim Inner Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.frontRimOutterWidth" label="Front Rim Outter Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.rearSpokeCount" label="Rear Spoke Count"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.rearRimDepth" label="Rear Rim Depth(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.rearRimInnerWidth" label="Rear Rim Inner Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.rearRimOutterWidth" label="Rear Rim Outter Width(mm)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.riderWeightLimit" label="Rider Weight Limit(kg)"></v-text-field>
                            </v-col>  
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.spokeType" label="Spoke Type"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.warranty" label="Warranty(yr)"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.link" label="PandaPodium Link"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="8">
                              <v-file-input label="Image File(keep empty if no change is needed)" accept="image/png, image/jpeg" v-model = "updatePopUpWindowItems.imgFile" ></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field v-model="updatePopUpWindowItems.ppId" label="PandaPodium id"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue-darken-1"
                          variant="text"
                          @click="cancelEditWheel"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue-darken-1"
                          variant="text"
                          @click="updateWheelset"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-toolbar>
              </template>

              <template v-slot:[`item.delete`] = "{ item }" >
                  <v-icon
                      style = " margin-left: -2rem;"
                      size="small"
                      @click="deleteIconClick(item)"
                  >
                      mdi-delete
                  </v-icon>
              </template>

              <!-- edit icon -->
              <template v-slot:[`item.edit`] = "{ item }" >
                  <v-icon
                      style = " margin-left: -2rem;"
                      size="small"
                      @click="editIconClick(item)"
                  >
                    mdi-pencil
                  </v-icon>
              </template>


              <!-- img template for first col -->
              <template v-slot:[`item.imgLink`]="{ item }">
                  <div :style="{ 'margin-left': window.width >= 768 ? '-0rem' : '-1.5rem' }">
                      <!-- wrap img in link redirecting   to item.link -->
                      <a :href="item.link">
                          <v-img
                              :src="item.imgLink"
                              :max-height="window.width >= 768 ? '100' : '50'"
                              :style="{ 'min-width': window.width >= 768 ? '150px' : '0px' }"
                              style="color: white; max-width: 150px;"
                              contain
                              @mouseenter="enableZoomMode && window.width >=768 &&  setEnlargedPic($event, item.imgLink, item.link)"
                              @mousemove="enableZoomMode && window.width >=768 && checkMouseLeave($event)"
                          >
                          </v-img> 
                      </a>
                      <span class="font-weight-bold">{{ item.shortName }}</span>
                  </div>
              </template>

              <template v-slot:[`item.price`]="{ item }">
                  <div>
                      <span  class="font-weight-bold">{{ item.price }}</span>
                  </div>
              </template>


              <!-- this removes pagination -->
              <template #bottom></template>
          </v-data-table>

          <!-- ADD NEW WHEELS -->
          <v-container>

              <!-- Drop-down -->
              <v-container :style="{ width: window.width >= 768 ? '80%' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '30px', marginTop: '0rem' }">
                      <div style = "display : flex;">
                          <v-img src="/images/wheelsetCompare/wheelUI.png" max-height="20" style = "color: white; min-width: 20px;" contain></v-img>
                          <h1 style = "font-size: 1rem; font-weight: 600; margin-left:5px">Add New Pair</h1>
                      </div>
                      
                      <div style = "width: 80%">
                          <!-- when an item is selected, call addWheel func -->
                          <!-- disable if enableSelectAllWheelsets is true -->
                          <v-autocomplete
                              v-if = "window.width >= 768"
                              :disabled = "enableSelectAllWheelsets"
                              :label= "autocompleteLabel"
                              hide-details
                              density="comfortable"
                              :items="computeSearchBar"
                              v-model = "addWheelSelected"
                              :menu-props="{maxHeight: window.width >= 768 ? 300 : 100}"
                              :error = "searchExists"
                              @update:modelValue = "addWheelset"
                              v-model:search="search"
                          >
                          </v-autocomplete>

                          <!-- disable if enableSelectAllWheelsets is true -->
                          <v-select
                              v-else
                              :label= "autocompleteLabel"
                              :disabled = "enableSelectAllWheelsets"
                              hide-details
                              dense
                              :items="computeSearchBar"
                              v-model = "addWheelSelected"
                              :menu-props="{maxHeight: window.width >= 768 ? 300 : 200}"
                              @update:modelValue = "addWheelset"
                              :error = "searchExists"
                          >
                          </v-select>
                      </div>
              </v-container>

              <!-- Sort options -->
              <v-container 
                  :style="{ width: window.width >= 768 ? '80%' : '100%', 'flex-direction' : window.width >= 768 ? 'row' : 'column', gap : window.width >= 768 ? '30px' : '0px'}" 
                  style = "display : flex; justify-content: center; align-items: center;"
              >
                  <div  style = "display : flex;" >
                      <h1 style = "font-size: 1rem; font-weight: 600; margin-left:5px">Search Sort By</h1>
                  </div>
                  <div 
                      :style="{ 'flex-wrap': window.width >= 768 ? 'nowrap' : 'wrap', 'width': window.width >= 768 ? 'auto' : '100vw'}"
                      style = " display : flex; justify-content: center; align-items: center;">
                      <v-checkbox style = "min-width: 84px;" label="Name" :hide-details="true" v-model="NameAtoZSort" ></v-checkbox>
                      <v-checkbox style = "min-width: 107px;" label="Price des " :hide-details="true" v-model="priceHtoLSort" ></v-checkbox>
                      <v-checkbox style = "min-width: 107px;" label="Price asc " :hide-details="true" v-model="priceLtoHSort" ></v-checkbox>
                      <v-checkbox style = "min-width: 121px;" label="Weight asc " :hide-details="true" v-model="weightLtoHSort" ></v-checkbox>
                      <v-checkbox style = "min-width: 121px;" label="Weight des " :hide-details="true" v-model="weightHtoLSort" ></v-checkbox>
                  </div>
              </v-container>

                <!-- Select All Checkbox -->
                <v-container
                    style = "display : flex; justify-content: center; align-items: center; margin-top:-1.5rem;"
                >
                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }" >
                                <v-checkbox 
                                    v-bind="props"
                                    class="d-inline-flex" 
                                    style = "font-weight:600;" 
                                    v-model="enableSelectAllWheelsets"
                                    label="Select All"
                                >
                                </v-checkbox>
                            </template>
                            <span> Add all available wheelsets to the comparing table </span>
                        </v-tooltip>
                </v-container>
          </v-container>
      </v-container>

      <!-- ENLARGED PICS -->
      <div ref = "enlargePic" @mousemove = "checkMouseLeave($event)">
          <div v-for = "(item, index) in items" :key = index>
              <div 
                  class = enlarged-image-container
                  :style ="{'cursor' : hoverClickable ? 'pointer' : 'default'}"
                  style = "display: none;"
                  @click = "hoverImgClick($event)"
              >
                  <img :src="item.imgLink" class="enlarged-image" style = "display: none;">
              </div>
          </div>
      </div>

      <!-- SNACK-BAR -->
      <v-snackbar
        v-show = "snackbar.show"
        v-model="snackbar.show"
        :color="snackbar.color"
        timeout="3000"
        bottom
      >
        {{ snackbar.text }}
      </v-snackbar>

      <!-- FEEDBACK TITLE -->
      <v-row 
          no-gutters 
          style = "justify-content: center; align-items: center; "
          :style = "{ 'margin-top' : window.width >=768 ? '2rem' : '1rem'}"
      >
          <v-col cols="auto" v-show="window.width >= 768" name="fade" is="transition-group">
              <v-img src="/images/wheelsetCompare/feedback.png" max-height="100" style = "color: white; min-width: 100px; padding-right:2rem" contain></v-img>
          </v-col>
          <v-col cols="auto">
              <h1 style = "  ">FEEDBACKS</h1>
          </v-col>
      </v-row>

      <!-- FEEDBACK TOOLBAR -->
      <v-container style = "margin-top:1rem">
        <v-toolbar
            flat
            style = "margin-bottom: 1rem;"
        >
            <v-toolbar-title>FEEDBACK CONTROL</v-toolbar-title>
            <!-- <v-divider
            class="mx-4"
            inset
            vertical
            ></v-divider> -->
            <v-spacer></v-spacer>

        </v-toolbar>
      </v-container>
      
      <!-- FEEDBACKS -->
      <v-container style = "display:flex; ">
        <FeedbackViewer @updateSnackbar = "onUpdateSnackbar"/>
      </v-container>

      
  </div>
</template>

<script>
import wheelsetService from '@/services/wheelsetService.js';
import FeedbackViewer from '@/components/adminControl/FeedbackViewer.vue';

export default {    
  name: 'AdminPage',
    components: {
        FeedbackViewer
    },
  data: () => ({
      jsonData: null,
      selected: [],
      searchItems: [],
      defaultSearchItems: [],
      advancedMode: true, 
      enableZoomMode: false,
      addWheelSelected: null,
      NameAtoZSort: true,
      priceHtoLSort: false,
      priceLtoHSort: false,
      weightLtoHSort: false,
      weightHtoLSort: false,
      searchExists: false,
      selectedItemImgLink: null,
      autocompleteLabel: 'Add wheelset to edit',
      hoverImgBorderXleft: 0,
      hoverImgBorderXright: 0,
      hoverImgBorderYtop: 0,
      hoverImgBorderYbuttom: 0,
      hoverClickable: false,
      hoverItemUrl: null,
      selectedImgElement: null,
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      search: '',
      currentToBeDeletedItem: null,
      currentToBecreateNewPopUpWindowItems: null,
      editDialog: false,
      enableSelectAllWheelsets:false,
      createNewPopUpWindowItems: {
        imgFile: null,
        name: '',
        shortName: '',
        price: '', //number
        DiscOrRim: '',
        weight: '', //number
        clincherOrTubelessOrHookless: '',
        frontSpokeCount: '', //number
        frontRimDepth: '', //number
        frontRimInnerWidth: '', //number
        frontRimOutterWidth: '', //number
        rearSpokeCount: '', //number
        rearRimDepth: '', //number
        rearRimInnerWidth: '', //number
        rearRimOutterWidth: '', //number
        riderWeightLimit: '', //number
        spokeType: '', //number
        warranty: '', //number
      },
      updatePopUpWindowItems: {
        imgFile: null,
        name: '',
        shortName: '',
        price: '', //number
        DiscOrRim: '',
        weight: '', //number
        clincherOrTubelessOrHookless: '',
        frontSpokeCount: '', //number
        frontRimDepth: '', //number
        frontRimInnerWidth: '', //number
        frontRimOutterWidth: '', //number
        rearSpokeCount: '', //number
        rearRimDepth: '', //number
        rearRimInnerWidth: '', //number
        rearRimOutterWidth: '', //number
        riderWeightLimit: '', //number
        spokeType: '', //number
        warranty: '', //number
        link: '',
        ppId: '',
      },
      window:{
          width: 0,
          height: 0},
      headers: [
          {title: '', value: 'delete', align: 'center', sortable: false},
          {title: '', value: 'edit', align: 'center', sortable: false},
          {title: '', value: 'imgLink', align: 'center', sortable: false, width: '100px', },
          // {title: '', value: 'plusButton', align: 'center', sortable: false },
          {title: 'Price', key:'price', value: 'price', align: 'center', },
          {title: 'Disc or Rim', value: 'DiscOrRim', align: 'center'},
          {title: 'Weight', value: 'weight', key:'weight', align: 'center', sortable: true},
          {title: 'Clincher or Tubeless or Hookless', value: 'clincherOrTubelessOrHookless', align: 'center'},
          {title: 'Front Spoke Count', value: 'frontSpokeCount', align: 'center', sortable: true},
          {title: 'Front Rim Depth', value: 'frontRimDepth', align: 'center', sortable: true},
          {title: 'Front Rim Inner Width', value: 'frontRimInnerWidth', align: 'center', sortable: true},
          {title: 'Front Rim Outter Width', value: 'frontRimOutterWidth', align: 'center', sortable: true},
          {title: 'Rear Spoke Count', value: 'rearSpokeCount', align: 'center', sortable: true},
          {title: 'Rear Rim Depth', value: 'rearRimDepth', align: 'center', sortable: true},
          {title: 'Rear Rim Inner Width', value: 'rearRimInnerWidth', align: 'center', sortable: true},
          {title: 'Rear Rim Outter Width', value: 'rearRimOutterWidth', align: 'center', sortable: true},
          // {title: 'Freehub Engagement Degrees', value: 'freehubEngagementDegrees', align: 'center', sortable: true},
          {title: 'Rider Weight Limit', value: 'riderWeightLimit', align: 'center', sortable: true},
          {title: 'Spoke', value: 'spokeType', align: 'center', sortable: false},
          {title: 'Warranty', value: 'warranty', align: 'center', sortable: true},
      ],
      items:[],
      itemsCopy: [], // save a backup of copy when user selects all wheelsets
      customKeySort: {
          //remove $ from each string and compare numeric value
          price: (p1, p2) => parseFloat(p1.replace('$', '')) - parseFloat(p2.replace('$', '')),

          // remove g from each string and compare numeric value
          weight: (w1, w2) => parseInt(w1.replace('g', '')) - parseInt(w2.replace('g', '')),
      },
      snackbar: {
        show: false,
        color: 'success',
        text: ''
      },
  }),
  methods: {
    // main function to compare items
    compareItems(){
        // make a copy of items array by value
        let comparedItems = JSON.parse(JSON.stringify(this.items));
        // find the selected item by name using this.selected[0]
        let selectedItem = comparedItems.find(item => item.name === this.selected[0]);

        // for every other item in comparedItems, compare every number value to selectedItem, 
        // find difference and alter the value in number format (+ or -)
        // skip the item if it's the same as selectedItem
        comparedItems.forEach(item => {
            if(item.name !== selectedItem.name){
                for (const [key, value] of Object.entries(item)) {
                    if(selectedItem[key] === null || selectedItem[key] === undefined || selectedItem[key] === '' || selectedItem[key] === -1){
                            // do nothing
                    } else if(value === null || value === undefined || value === '' || value === -1){
                        item[key] = 'N/A';
                    } 
                    // else if the value can be turned from string to number
                    else if(!isNaN(value) && key != "warranty"){
                        let difference = value - selectedItem[key];
                        item[key] = difference;
                    }

                    // if(typeof value === 'number'){
                    //     let difference = value - selectedItem[key];
                    //     item[key] = difference;
                    // }
                }
            }
        });

        return comparedItems;
    },

    // add unit to the values in items
    formatItems(items){
        // make a copy of items array by value
        let formattedItems = JSON.parse(JSON.stringify(items));

        // for every item in formattedItems, add unit to every number value
        // if not comparing
        if(this.selected.length === 0){
            formattedItems.forEach(item => {
                for (const [key, value] of Object.entries(item)) {
                    if(value === null || value === undefined || value === '' || value === -1){
                        item[key] = 'N/A';
                    }
                    else if(key === 'price'){
                        // if negative, add - sign in front of $
                        if(value < 0){
                            item[key] = '-$' + Math.abs(value);
                        } else {
                            item[key] = '$' + value;
                        }
                    } 
                    else if (key === 'weight'){
                        item[key] = value + 'g';
                    }
                    else if (key === 'frontRimDepth' || key === 'rearRimDepth'){
                        item[key] = value + 'mm';
                    }
                    else if (key === 'frontRimInnerWidth' || key === 'frontRimOutterWidth' || key === 'rearRimInnerWidth' || key === 'rearRimOutterWidth'){
                        item[key] = value + 'mm';
                    }
                    else if (key === 'freehubEngagementDegrees'){
                        item[key] = value + '°';
                    }
                    else if (key === 'riderWeightLimit'){
                        item[key] = value + 'kg';
                    }
                }
            });
        } 
        // if comparing, add + to positive number, negative number is already handled
        else {
            formattedItems.forEach(item => {
                    for (const [key, value] of Object.entries(item)) {
                        if(value === null || value === undefined || value === '' || value === 'N/A' || value === -1){
                            item[key] = 'N/A';
                        }
                        else if(key === 'price'){
                            // if negative, add - sign in front of $
                            if(value < 0){
                                item[key] = '-$' + Math.abs(value);
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+$' + value;
                                }else{
                                    item[key] = '$' + value;
                                }
                            }
                        } 
                        else if (key === 'weight'){
                            // if weight is negative, don't add + sign
                            if(value < 0){
                                item[key] = value + 'g';
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+' + value + 'g';
                                }else{
                                    item[key] = value + 'g';
                                }
                            }
                        }
                        // do the same for negative value for every other values
                        else if (key === 'frontRimDepth' || key === 'rearRimDepth'){
                            if(value < 0){
                                item[key] = value + 'mm';
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+' + value + 'mm';
                                }else{
                                    item[key] = value + 'mm';
                                }
                            }
                        }
                        else if (key === 'frontRimInnerWidth' || key === 'frontRimOutterWidth' || key === 'rearRimInnerWidth' || key === 'rearRimOutterWidth'){
                            if(value < 0){
                                item[key] = value + 'mm';
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+' + value + 'mm';
                                }else{
                                    item[key] = value + 'mm';
                                }
                            }
                        }
                        else if (key === 'freehubEngagementDegrees'){
                            if(value < 0){
                                item[key] = value + '°';
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+' + value + '°';
                                }else{
                                    item[key] = value + '°';
                                }
                            }
                        }
                        else if (key === 'riderWeightLimit'){
                            if(value < 0){
                                item[key] = value + 'kg';
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+' + value + 'kg';
                                }else{
                                    item[key] = value + 'kg';
                                }
                            }
                        }
                        else if (key === 'frontSpokeCount' || key === 'rearSpokeCount'){
                            if(value < 0){
                                item[key] = value;
                            } else {
                                if(item.name !== this.selected[0]){
                                    item[key] = '+' + value;
                                }else{
                                    item[key] = value;
                                }
                            }
                        }
                    }
                }
            );

            // iterate through selected items' value, if the any col is N/A, 
            // check if other item's same col starts with +/-, if so, remove the sign
            let selectedItem = formattedItems.find(item => item.name === this.selected[0]);
            for (const [key, value] of Object.entries(selectedItem)) {
                if(value === 'N/A'){
                    formattedItems.forEach(item => {
                        if(item.name !== selectedItem.name){
                            if(item[key].startsWith('+') || item[key].startsWith('-')){
                                item[key] = item[key].substring(1);
                            }
                        }
                    });
                }
            }
            
        }
        return formattedItems;
    },

    handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },

    setEnlargedPic(event,imgLink,urlLink){
        // {
        //     "x": 202.703125,
        //     "y": 238,
        //     "width": 150,
        //     "height": 100,
        //     "top": 238,
        //     "right": 352.703125,
        //     "bottom": 338,
        //     "left": 202.703125
        // }
        // get x and y border of the orignial image, if mouse is outside, hide the enlarged image
        this.hoverImgBorderXright = event.target.getBoundingClientRect().right;
        this.hoverImgBorderXleft = event.target.getBoundingClientRect().left;
        this.hoverImgBorderYtop = event.target.getBoundingClientRect().top;
        this.hoverImgBorderYbuttom = event.target.getBoundingClientRect().bottom;
        this.hoverItemUrl = urlLink;
        this.selectedItemImgLink = imgLink;
        // console.log(imgLink)
        // // // console log src attribute of the img
        // console.log(this.$refs.enlargePic.children)
        // iterate through all the enlarged images and find the one that matches the imgLink
        let enlargedImages = this.$refs.enlargePic.children;
        for(let i = 0; i < enlargedImages.length; i++){
            if(enlargedImages[i].children[0].children[0].src === imgLink){
                this.selectedImgElement = enlargedImages[i].children[0];
                
                // wait half a second before displaying the enlarged image
                setTimeout(() => {
                    this.selectedImgElement.style.display = 'flex';
                    this.selectedImgElement.children[0].style.display = 'block';
                }, 100);

                break;
            } 
        }
    },

    checkMouseLeave(event) {
        if(event.clientX < this.hoverImgBorderXleft || event.clientX > this.hoverImgBorderXright || event.clientY < this.hoverImgBorderYtop || event.clientY > this.hoverImgBorderYbuttom){
            this.selectedImgElement.style.display = 'none';
            this.selectedImgElement.children[0].style.display = 'none';
            this.selectedImgElement = null;
        }

        // if cursor is still on the small image, make cursor a pointer that 
        else{
            this.hoverClickable = true;
        }
        // Check if the mouse is still within the boundaries of the enlarged image
        // const enlargedImageContainer = document.querySelector('.enlarged-image-container');
        // const rect = enlargedImageContainer.getBoundingClientRect();
        // console.log(rect);
        // const mouseX = event.clientX;
        // const mouseY = event.clientY;
        // if (mouseX < rect.left || mouseX > rect.right || mouseY < rect.top || mouseY > rect.bottom) {
        //     // If the mouse is outside the boundaries, hide the enlarged image
        //     this.selectedItemImgLink = null;
        // }
    },

    hoverImgClick(){
        // if the enlarged image is clicked, redirect to the item's link
        if(this.hoverClickable){
            window.open(this.hoverItemUrl, '_blank');
        }
    },

    sortSearchHelper(sortVmodel){
        if(sortVmodel){
            sortVmodel = true;
        }else{
            this.priceLtoHSort = false;
            this.weightLtoHSort = false;
            this.weightHtoLSort = false;
            this.NameAtoZSort = false;
            this.addWheelSelected = null;
            this.searchExists = false;
        }
    },

    addWheelset() {
        let val = this.addWheelSelected;
        // val = ' $' + price + ' ' + weight + 'g' + ' ' + name
        // split val into name, price, weight
        // find the item in jsonData.data that has the same name as name
        // add the item to items
        if(val !== null && val !== undefined && val !== ''){
            // name = all elements concat expect for first 3
            let name = val.split(' ').slice(3).join(' ');
            let selectedItem = this.jsonData.data.find(item => item.name === name);
            if(selectedItem !== null & selectedItem !== undefined){

                // if selectedItem is already in items, don't add it again, alert user
                if(this.items.find(item => item.name === selectedItem.name)){
                    this.searchExists = true;
                    this.autocompleteLabel = 'Item already added';
                    this.$nextTick(() => {
                        this.addWheelSelected = null;
                        this.search = '';
                    });

                    // if autocompleteLabel is still 'Item already added' after 2 seconds, reset it
                    setTimeout(() => {
                        if(this.autocompleteLabel === 'Item already added'){
                            this.autocompleteLabel = 'Search for wheelset';
                            this.searchExists = false;
                        }
                    }, 2000);
                } else {
                    this.$nextTick(() => {
                        // reset the selected value
                        this.addWheelSelected = null;
                        this.searchExists = false;
                        this.search = '';
                        this.autocompleteLabel = 'Search for wheelset';
                    });

                    
                    // ---------------------------------------------
                    // ADD ITEM TO ITEMS 
                    this.items.push(selectedItem);
                    
                    // push all id of selected items to local storage
                    let selectedItemsId = this.items.map(item => item.id);
                    localStorage.setItem('wheelCompareItemsId', JSON.stringify(selectedItemsId));

                    // ---------------------------------------------
                    localStorage.setItem('wheelCompareItems', JSON.stringify(this.items));

                }
            }
        }

        // let selectedItem = this.jsonData.data.find(item => item.name === val);
        // if(selectedItem !== null & selectedItem !== undefined){
        //     this.items.push(selectedItem);
        //     console.log(selectedItem)
        //     // reset the selected value
        //     this.addWheelSelected = null;
        // }
    },

    //  ------------------------------------------------------------------
    // ADMIN TOOL

    // UPDATE request to update a wheelset
    async updateWheelset(){
        let selected = this.currentToBecreateNewPopUpWindowItems;
        if(selected === null){alert("no wheel selected"); return;}

        // input validation
        if(!this.checkcreateNewPopUpWindowItems(this.updatePopUpWindowItems, 'UPDATE')){return;}
        else{
            // call service to update the item
            try{
                const response = await wheelsetService.updateWheelset(selected.id, this.updatePopUpWindowItems);
                if(response.status === 200){
                    // close the dialog and refresh the page
                    this.editDialog = false;
                    this.snackbar.show = true;
                    this.snackbar.text = 'Successfully updated';
                    this.snackbar.color = 'success';

                    // wait 500ms
                    setTimeout(() => {
                        this.$router.go();
                    }, 500);
                }
            }catch(err){
                alert(err.response.data.message)

                // if 401(unauthorized), redirect to login page
                if(err.response.status === 401){
                    this.$router.push('/ppauth/adminLogin');
                }
            }
        }
    },

    // POST request to add a new wheelset
    async postWheelset(){
        // input validation
        if(!this.checkcreateNewPopUpWindowItems(this.createNewPopUpWindowItems)){return;}
        else{     
            try{
                const response = await wheelsetService.createWheelset(this.createNewPopUpWindowItems);
                if(response.status === 201){
                    // close the dialog and refresh the page
                    this.editDialog = false;
                    this.snackbar.show = true;
                    this.snackbar.text = 'Successfully added';
                    this.snackbar.color = 'success';
                    this.$router.go();
                }
            }catch(err){
                alert(err.response.data.message)

                // if 401(unauthorized), redirect to login page
                if(err.response.status === 401){
                    this.$router.push('/ppauth/adminLogin');
                }
            }
        }

    },

    // DELETE request to delete a wheelset
    async deleteItemConfirm(){
        let item = this.currentToBeDeletedItem;
        // find the index of the item in items
        let index = this.items.findIndex(i => i.name === item.name);
        let id = this.items[index].id;


        // call service to delete the item from db
        // param = id
        try{
            const response = await wheelsetService.deleteWheelset(id);

            if(response.status === 200){
            this.dialogDelete = false;
            this.currentToBeDeletedItem = null;
            this.snackbar.show = true;
            this.snackbar.text = 'Successfully deleted';
            this.snackbar.color = 'success';
            // remove the item from items
            this.items.splice(index, 1);

            // update the local storage by id
            // localStorage.setItem('wheelCompareItems', JSON.stringify(this.items));

            // remove all id of selected items from local storage
            let selectedItemsId = this.items.map(item => item.id);
            localStorage.setItem('wheelCompareItemsId', JSON.stringify(selectedItemsId));

            // refresh the page
            setTimeout(() => {
                this.$router.go();
            }, 500);
        }
        }catch(err){

            if(err.response.status === 401){
                    this.$router.push('/ppauth/adminLogin');
            }

            else{
                this.dialogDelete = false;
                this.snackbar.show = true;
                this.snackbar.text = err.response.data.message;
                this.snackbar.color = 'error';

                // refresh the page after 2 seconds
                setTimeout(() => {
                    this.$router.go();
                }, 2000);
            }
        }


    

    },

    // check createNewPopUpWindowItems correctness
    checkcreateNewPopUpWindowItems(obj, method = 'POST'){
        let error = false;
        // helper function that that takes a string, return -1 if string = '', return the number if string is a number, else return NaN
        const checkNumber = (str) => {
            if(str === ''){
                return -1;
            } else if(Number.isFinite(Number(str))){
                return str;
            } else {
                alert(str + " is not a valid number");
                error = true;
                return str;
            }
        }
        obj.frontSpokeCount = checkNumber(obj.frontSpokeCount);
        obj.frontRimDepth = checkNumber(obj.frontRimDepth);
        obj.frontRimInnerWidth = checkNumber(obj.frontRimInnerWidth);
        obj.frontRimOutterWidth = checkNumber(obj.frontRimOutterWidth);
        obj.rearSpokeCount = checkNumber(obj.rearSpokeCount);
        obj.rearRimDepth = checkNumber(obj.rearRimDepth);
        obj.rearRimInnerWidth = checkNumber(obj.rearRimInnerWidth);
        obj.rearRimOutterWidth = checkNumber(obj.rearRimOutterWidth);
        obj.riderWeightLimit = checkNumber(obj.riderWeightLimit);
        obj.warranty = checkNumber(obj.warranty);
        obj.ppId = checkNumber(obj.ppId);   
        if(error){return false;}


        // REQUIRED FIELDS
        else if((obj.imgFile === null || obj.imgFile === undefined ) && method === 'POST'){
            alert('Please upload an image');
            return false;
        }
        else if(obj.name === '' || obj.name === undefined){
            alert('Please enter a name');
            return false;
        }
        else if(obj.price === '' || obj.price === undefined || Number.isNaN(Number(obj.price))){
            alert('Please enter a price or enter a valid number for price');
            return false;
        }
        else if(obj.DiscOrRim === '' || obj.DiscOrRim === undefined || !['disc','rim'].includes(obj.DiscOrRim.toLowerCase())){
            alert('Please enter Disc or Rim');
            return false;
        }   
        else if(obj.weight === -1 || obj.weight === undefined || obj.weight === '' || Number.isNaN(Number(obj.weight))){
            alert('Please enter a weight');
            return false;
        }
        else if(!['clincher','tubeless','hookless'].includes(obj.clincherOrTubelessOrHookless.toLowerCase())){
            alert('Please enter Clincher or Tubeless or Hookless');
            return false;
        }
        // OPTIONAL FIELDS
        else if(obj.spokeType === '' || obj.spokeType === undefined){
            alert('Please enter Spoke Type');
            return false;
        }
        // if NaN, report error, else set to 'returned value'
        // if(frontSpokeCount === NaN){
        //     alert('Please enter Front Spoke Count as a number');
        //     return false;
        // } else {
        //     obj.frontSpokeCount = frontSpokeCount;
        // }


        else if(obj.link === '' || obj.link === undefined){
            alert('Please enter a link');
            return false;
        }
        return true;
    },

    editIconClick(item){
        // find selected Item
        let index = this.items.findIndex(i => i.name === item.name);
        let selected = this.items[index];
        this.currentToBecreateNewPopUpWindowItems = selected;
        console.log(selected)
        // render updatePopUpWindowItems based on item Object
        this.updatePopUpWindowItems.name = selected.name;
        this.updatePopUpWindowItems.shortName = selected.shortName;
        this.updatePopUpWindowItems.price = selected.price;
        this.updatePopUpWindowItems.weight = selected.weight;
        this.updatePopUpWindowItems.DiscOrRim = selected.DiscOrRim;
        this.updatePopUpWindowItems.clincherOrTubelessOrHookless = selected.clincherOrTubelessOrHookless;
        this.updatePopUpWindowItems.frontSpokeCount = selected.frontSpokeCount;
        this.updatePopUpWindowItems.frontRimDepth = selected.frontRimDepth;
        this.updatePopUpWindowItems.frontRimInnerWidth = selected.frontRimInnerWidth;
        this.updatePopUpWindowItems.frontRimOutterWidth = selected.frontRimOutterWidth;
        this.updatePopUpWindowItems.rearSpokeCount = selected.rearSpokeCount;
        this.updatePopUpWindowItems.rearRimDepth = selected.rearRimDepth;
        this.updatePopUpWindowItems.rearRimInnerWidth = selected.rearRimInnerWidth;
        this.updatePopUpWindowItems.rearRimOutterWidth = selected.rearRimOutterWidth;
        this.updatePopUpWindowItems.riderWeightLimit = selected.riderWeightLimit;
        this.updatePopUpWindowItems.spokeType = selected.spokeType;
        this.updatePopUpWindowItems.warranty = selected.warranty;
        this.updatePopUpWindowItems.link = selected.link;
        this.updatePopUpWindowItems.id = selected.id;
        this.updatePopUpWindowItems.ppId = selected.ppId;  
        this.editDialog = true;

    },

    cancelEditWheel(){
        if(confirm){
            this.editDialog = false;
        }
        this.currentToBecreateNewPopUpWindowItems = null;
    },

    cancelAddWheel(){
        // close the dialog
        const confirm = window.confirm('All changes will be lost. Are you sure you want to cancel?');
        if(confirm){
            this.dialog = false;
        }
    },

    deleteIconClick(item){
        // create an pop up window that alert the user about data deletion
        this.dialogDelete = true;
        this.currentToBeDeletedItem = item;
    },

    closeDelete(){
        this.dialogDelete = false;
        this.currentToBeDeletedItem = null;
    },

    onUpdateSnackbar(text){
        this.snackbar.text = text;
        this.snackbar.show = true;
    }
  },
  async mounted() {
      // Code to run when the component is mounted
      document.title = "Wheelset Compare";
      // set to favicon = https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png
      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = "https://www.pandapodium.cc/wp-content/uploads/2023/05/Panda_Podium_LogoFavicon.png";

      // Fetch JSON data
      try {
              const response = await wheelsetService.getAllWheelsetsAdmin()
              this.jsonData = {'data' : response.data.data.results};
              
              // extract price, weight, name, imgLink from jsonData and add to searchItems
              this.searchItems = this.jsonData.data.map(item => {
                  return {
                      name: item.name,
                      imgLink: item.imgLink,
                      price: item.price,
                      weight: item.weight
                  }
              });

              // make a copy of searchItems before sorting
              this.defaultSearchItems = JSON.parse(JSON.stringify(this.searchItems));

              // Set default sorting to NameAtoZSort
              this.$nextTick(() => {
                  this.searchItems.sort((a, b) => (a.name > b.name) ? 1 : -1);
              })

             // set localstorage
             if(localStorage.getItem('wheelCompareItemsId')){
                    let storedItemsId = JSON.parse(localStorage.getItem('wheelCompareItemsId'));
                    this.items = this.jsonData.data.filter(item => storedItemsId.includes(item.id));
                } else{
                    this.items = [];
                }
          } catch (error) {
              console.error('Error fetching JSON:', error);
      }



  },
  computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

      currentHeaders() {
          // Dynamically select headers based on the state of advancedMode
          if(this.advancedMode){
              return this.headers;
          } else {
              return this.headers.filter(header => 
              header.value !== 'freehubEngagementDegrees' && header.value !== 'riderWeightLimit'
               && header.value !== 'frontRimOutterWidth'
               && header.value !== 'rearRimOutterWidth' && header.value !== 'frontSpokeCount' 
              && header.value !== 'rearSpokeCount' && header.value !== 'warranty' && header.value !== 'spokeType');
          }
      },

      currentItems(){
          if(this.selected.length === 0){
              return this.formatItems(this.items);
          } else {
              return this. formatItems (this.compareItems());
          }
      },

      computeSearchBar(){
          // combine searchItems.name and searchItems.price and searchItems.weight
          // store each string in an object with {name: string,disabled: false}
          // let combined = this.searchItems.map(item => {
          //     return {name: item.name + ' $' + item.price + ' ' + item.weight + 'g', disabled: false};
          // });
          // return combined;
          let combined = this.searchItems.map(item => {
              return ' $' + item.price + ' ' + item.weight + 'g' + ' ' + item.name;
          });
          return combined;
      },


  },
  // Other component options go here
  watch: {
      priceHtoLSort: function (val) {
          if(val){
              this.priceLtoHSort = false;
              this.weightLtoHSort = false;
              this.weightHtoLSort = false;
              this.NameAtoZSort = false;
              this.addWheelSelected = null;
              this.searchExists = false;
              this.autocompleteLabel = 'Search for wheelset';
              this.searchItems.sort((a, b) => (a.price > b.price) ? -1 : 1);

              // set sortOption state to priceHtoLSort in local storage
              localStorage.setItem('wheelCompareSortOption', 'priceHtoLSort');
          }
      },

      priceLtoHSort: function (val) {
          if(val){
              this.priceHtoLSort = false;
              this.weightLtoHSort = false;
              this.weightHtoLSort = false;
              this.NameAtoZSort = false;
              this.addWheelSelected = null;
              this.searchExists = false;
              this.autocompleteLabel = 'Search for wheelset';
              this.searchItems.sort((a, b) => (a.price > b.price) ? 1 : -1);

              // set sortOption state to priceLtoHSort in local storage
              localStorage.setItem('wheelCompareSortOption', 'priceLtoHSort');
          }
      },

      weightLtoHSort: function (val) {
          if(val){
              this.priceHtoLSort = false;
              this.priceLtoHSort = false;
              this.weightHtoLSort = false;
              this.NameAtoZSort = false;
              this.addWheelSelected = null;
              this.searchExists = false;
              this.autocompleteLabel = 'Search for wheelset';
              this.searchItems.sort((a, b) => (a.weight > b.weight) ? 1 : -1);

              // set sortOption state to weightLtoHSort in local storage
              localStorage.setItem('wheelCompareSortOption', 'weightLtoHSort');
          }
      },

      weightHtoLSort: function (val) {
          if(val){
              this.priceHtoLSort = false;
              this.priceLtoHSort = false;
              this.weightLtoHSort = false;
              this.NameAtoZSort = false;
              this.addWheelSelected = null;
              this.searchExists = false;
              this.autocompleteLabel = 'Search for wheelset';
              this.searchItems.sort((a, b) => (a.weight > b.weight) ? -1 : 1);

              // set sortOption state to weightHtoLSort in local storage
              localStorage.setItem('wheelCompareSortOption', 'weightHtoLSort');
          }
      },

      NameAtoZSort: function (val) {
          if(val){
              this.priceHtoLSort = false;
              this.priceLtoHSort = false;
              this.weightLtoHSort = false;
              this.weightHtoLSort = false;
              this.addWheelSelected = null;
              this.searchExists = false;
              this.autocompleteLabel = 'Search for wheelset';
              this.searchItems.sort((a, b) => (a.name > b.name) ? 1 : -1);

              // set sortOption state to NameAtoZSort in local storage
              localStorage.setItem('wheelCompareSortOption', 'NameAtoZSort');
          }
      },

      enableZoomMode: function(val){
          // save the state of enableZoomMode to local storage
          localStorage.setItem('wheelCompareEnableZoomMode', val);
      },

      enableSelectAllWheelsets:function(val){
        // when user selects this option
        // first save a copy of items to itemsCopy
        // then clear items and add all wheelsets to items
        // when user unselects this option, restore items to itemsCopy
            if(val){
                this.itemsCopy = JSON.parse(JSON.stringify(this.items));
                this.items = this.jsonData.data;
            } else {
                this.items = this.itemsCopy;
            }
        },
  },
  created() {
    // Code to run when the component is created

      // set the state of enableZoomMode from local storage
      const storedEnableZoomMode = localStorage.getItem('wheelCompareEnableZoomMode');
      if (storedEnableZoomMode) {
          this.enableZoomMode = JSON.parse(storedEnableZoomMode);
      } else{
          this.enableZoomMode = false;
      }


      // set the state of sortOption from local storage
      const storedSortOption = localStorage.getItem('wheelCompareSortOption');
      if (storedSortOption) {
          if(storedSortOption === 'priceHtoLSort'){
              this.priceHtoLSort = true;
          } else if(storedSortOption === 'priceLtoHSort'){
              this.priceLtoHSort = true;
          } else if(storedSortOption === 'weightLtoHSort'){
              this.weightLtoHSort = true;
          } else if(storedSortOption === 'weightHtoLSort'){
              this.weightHtoLSort = true;
          } else if(storedSortOption === 'NameAtoZSort'){
              this.NameAtoZSort = true;
          }
      }
      

      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  unmounted() {
      window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>

/* overwrite font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.mainCont{
  font-family: 'Poppins' !important
}

/* Your component styles go here */
.v-data-table th {
font-weight: bold;
}

.nonAdvancedMode{
  max-width: 80% !important;
}


tr.v-data-table__selected {
background: #7d92f5 !important;
}

.v-data-table {
table {
  tr {
    th, td {
      padding: 0 0px !important;
    }
  }
}
}

.enlarged-image-container {
position: absolute;
top: 0;
left: 0;
z-index: 999;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7); /* semi-transparent background */
}

.enlarged-image {
max-width: 80%;
max-height: 80%;
object-fit: contain;
}

.fade-enter-active, .fade-leave-active {
      transition: all 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

</style>